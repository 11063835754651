<template>

<app-wrapper class="banner" v-bgimage="$state.page.banner_image">

	<app-wrapper :content="true" class="banner-content">
	
		<h2 v-html="$state.page.banner_title" />

		<p>{{ $state.page.banner_subtitle }}</p>

	</app-wrapper>

	<div class="banner-scroll" v-if="window.is.desktop" />

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.banner {
	height: 100vh;
	background-position: 50% 50%;
	background-size: cover;
	background-color: #FEC32D;
}

.is-tablet .banner {
	height: 544px;
}

.is-mobile .banner {
	height: 344px;
	background-position: calc(50% - 15px) 50%;
}

.banner-content {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	padding: 0px 10px!important;
}

.banner-content h2 {
	font-size: 85px;
	line-height: 90px;
	font-weight: 700;
	color: #fff;
	padding-bottom: 57px;
	position: relative;
	max-width: 660px;
	margin-bottom: 57px;
}

.is-tablet .banner-content h2 {
	font-size: 48px;
	line-height: 50px;
	padding-bottom: 32px;
	max-width: 364px;
	margin-bottom: 32px;
}

.is-mobile .banner-content h2 {
	font-size: 28px;
	line-height: 34px;
	padding-bottom: 32px;
	margin-bottom: 32px;
}

.banner-content h2:after {
	content: ' ';
	display: block;
	position: absolute;
	background-color: #FF2D07;
	width: 119px;
	height: 6px;
	bottom: -3px;
}

.banner-content p {
	max-width: 600px;
	font-weight: 500;
    font-size: 24px;
    letter-spacing: 8.4px;
    line-height: 40px;
    color: #1A2046;
}

.is-tablet .banner-content p {
	font-size: 18px;
	letter-spacing: 6.3px;
	line-height: 25px;
	max-width: 314px;
}

.is-mobile .banner-content p {
	font-size: 13px;
	letter-spacing: 4.55px;
	max-width: 50%;
	line-height: 20px;
}

</style>
